import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/img/svg/logo.svg'

function Footer() {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer__inner'>
          <div className='footer__inner-items'>
            <div className='footer__inner-item'>
              <div className='footer__inner-item-title'>Address</div>
              <ul>
                <li>
                  <a
                    href='https://maps.app.goo.gl/FdC3XujbCaSymAzV6'
                    target='_blank'
                  >
                    1235 Bay Street, Suite 700, Toronto, Ontario, <br /> M5R3K4, Canada
                  </a>
                </li>
              </ul>
            </div>
            <div className='footer__inner-item'>
              <div className='footer__inner-item-title'>Links</div>
              <ul>
                <li>
                  <NavLink to='/'>Home</NavLink>
                </li>
                <li>
                  <NavLink to='/shop'>Shop</NavLink>
                </li>
                <li>
                  <NavLink to='/about'>About</NavLink>
                </li>
                <li>
                  <NavLink to='/contact'>Contact</NavLink>
                </li>
              </ul>
            </div>
            <div className='footer__inner-item'>
              <div className='footer__inner-item-title'>Help</div>
              <ul>
                <li>
                  <NavLink to='/'>Payment Options</NavLink>
                </li>
                <li>
                  <NavLink to='/'>Privacy Policy</NavLink>
                </li>
              </ul>
            </div>
            <div className='footer__inner-item'>
              <div className='footer__inner-item-title'>Newsletter</div>
              <div className='subscribe'>
                <input type='email' placeholder='Enter Your Email Address' />
                <button type='submit'>Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
