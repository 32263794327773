import React from 'react'
import { NavLink } from 'react-router-dom'
import { FurnitureCard } from '../components/FurnitureCard'
import furnitureData from '../data/furnitureData.json'
function HomePage() {
  return (
    <>
      <div className='header-banner'>
        <img src={require(`../assets/img/scandinavian.jpg`)} alt='' />
        <div className='container'>
          <div className='header-banner__inner'>
            <div className='header-banner-card'>
              <div className='header-banner-card-label'>New Arrival</div>
              <h1>Discover Our New Collection</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis.
              </p>
              <NavLink to='/shop'>BUY Now</NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className='collection'>
        <div className='container'>
          <div className='collection__inner'>
            <h2>SHOP BY COLLECTION</h2>
            <div className='collection-items'>
              <NavLink to='/shop' className='collection-item'>
                <div className='collection-item-img'>
                  <img src={require(`../assets/img/dining.jpg`)} alt='' />
                </div>
                <div className='collection-item-text'>Dining</div>
              </NavLink>
              <NavLink to='/shop' className='collection-item'>
                <div className='collection-item-img'>
                  <img src={require(`../assets/img/living.jpg`)} alt='' />
                </div>
                <div className='collection-item-text'>Living</div>
              </NavLink>
              <NavLink to='/shop' className='collection-item'>
                <div className='collection-item-img'>
                  <img src={require(`../assets/img/bedroom.jpg`)} alt='' />
                </div>
                <div className='collection-item-text'>Bedroom</div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className='sellers'>
        <div className='container'>
          <div className='sellers__inner'>
            <h2>BEST SELLERS</h2>
            <div className='sellers-items'>
              {furnitureData.slice(0, 6).map((item, idx) => {
                return (
                  <FurnitureCard
                    key={idx}
                    img={item.img}
                    title={item.title}
                    price={item.price}
                    discountedPrice={item.discountedPrice}
                    page={item.id}
                  />
                )
              })}
            </div>
            <NavLink to='/shop'>Show More</NavLink>
          </div>
        </div>
      </div>
      <div className='best-furniture'>
        <div className='container'>
          <div className='best-furniture__inner'>
            <div className='best-furniture-text'>
              <div className='best-furniture-text-title'>
                The Best Furniture Manufacturer of your choice
              </div>
              <div className='best-furniture-text-description'>
                Furnitre power is a software as services for multiperpose
                business management system, expecially for them who are running
                two or more business exploree the future Furnitre power is a
                software as services \
              </div>
            </div>
            <div className='best-furniture-img'>
              <img src={require(`../assets/img/best-furniture.png`)} alt='' />
            </div>
          </div>
        </div>
      </div>
      <div className='discount'>
        <img src={require(`../assets/img/discount-bg.jpg`)} alt='' />
        <div className='container'>
          <div className='discount__inner'>
            <div className='discount-box'>
              <div className='discount-box-title'>
                Get more discount Off your order
              </div>
              <div className='discount-box-text'>Join our mailing list</div>
              <div className='discount-box-form'>
                <input type='email' placeholder='Your email address' />
                <button type='submit'>Shop Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { HomePage }
