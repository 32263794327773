import React, { useState, useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as FilterIcon } from '../assets/img/svg/filter-icon.svg'
import { ReactComponent as GridBigRound } from '../assets/img/svg/grid-big-round.svg'
import { ReactComponent as ViewList } from '../assets/img/svg/view-list.svg'
import { FurnitureCard } from '../components/FurnitureCard'
import furnitureData from '../data/furnitureData.json'
import Pagination from '../components/Pagination'

let PageSize = 25

function ShopPage() {
  const [currentPage, setCurrentPage] = useState(1)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return furnitureData.slice(firstPageIndex, lastPageIndex)
  }, [currentPage])

  return (
    <>
      <div className='banner-breadcrumbs'>
        <img src={require(`../assets/img/bg-breadcrumbs.png`)} alt='' />
        <div className='container'>
          <div className='banner-breadcrumbs__inner'>
            <div className='banner-breadcrumbs-title'>Shop</div>
            <div className='banner-breadcrumbs-list'>
              <NavLink to={'/'}>Home</NavLink>
              <span>Shop</span>
            </div>
          </div>
        </div>
      </div>
      <div className='filter'>
        <div className='container'>
          <div className='filter__inner'>
            <div className='filter-left'>
              <div className='filter-btn'>
                <FilterIcon />
                Filter
              </div>
              <div className='filter-list'>
                <div className='filter-list-item'>
                  <GridBigRound />
                </div>
                <div className='filter-list-item'>
                  <ViewList />
                </div>
              </div>
              <div className='filter-text'>
                Showing 1–25 of {furnitureData.length} results
              </div>
            </div>
            <div className='filter-right'>
              <div className='filter-show'>
                Show
                <select>
                  <option value=''>25</option>
                </select>
              </div>
              <div className='filter-short'>
                Short by
                <select>
                  <option value=''>Default</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='shop-list'>
        <div className='container'>
          <div className='shop-list__inner'>
            <div className='shop-list-items'>
              {currentTableData.map((item, index) => {
                return (
                  <FurnitureCard
                    key={item.id}
                    img={item.img}
                    title={item.title}
                    price={item.price}
                    discountedPrice={item.discountedPrice}
                    page={item.id}
                  />
                )
              })}
            </div>

            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={furnitureData.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export { ShopPage }
