import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as CartFurniture } from '../assets/img/svg/cart-furniture.svg'

function FurnitureCard({ page, img, title, price, discountedPrice }) {
  return (
    <NavLink to={`/shop/${page}`} className='furni-card'>
      <div className='furni-card-img'>
        <img src={require(`../assets/img/furniture/${img[0]}`)} alt='' />
      </div>
      <div className='furni-card-info'>
        <div className='furni-card-title'>{title}</div>
        <div className='furni-card-price'>
          <div className='furni-card-price-text'>
            {price ? <span>{price}</span> : null}
            {discountedPrice}
          </div>
          <div className='furni-card-price-icon'>
            <CartFurniture />
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export { FurnitureCard }
