import React, {useState} from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from './Header'
import { Footer } from './Footer'
import { ShopCart } from './ShopCart'
function Layout() {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenCart = () => {
    setIsOpen(!isOpen)
    document.body.classList.add('hiden')
  }
  const handleCloseCart = () => {
    setIsOpen(!isOpen)
    document.body.classList.remove('hiden')
  }
  return (
    /**
     * Markup to render only the content of pages, and elements that are repeated on each page are loaded only once 
     * 
     * This markup is also used to attach a footer to the bottom
     */
    <div className='wrapper'>
      <Header setIsOpen={handleOpenCart} />
      <main className='main'>
        <Outlet />
      </main>
      {isOpen && <ShopCart setIsOpen={handleCloseCart} />}
      <Footer />
    </div>
  )
}

export { Layout }
