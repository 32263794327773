import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Point } from '../assets/img/svg/point.svg'
import { ReactComponent as Phone } from '../assets/img/svg/phone.svg'
function ContactPage() {
  return (
    <>
      <div className='banner-breadcrumbs'>
        <img src={require(`../assets/img/bg-breadcrumbs.png`)} alt='' />
        <div className='container'>
          <div className='banner-breadcrumbs__inner'>
            <div className='banner-breadcrumbs-title'>Contact</div>
            <div className='banner-breadcrumbs-list'>
              <NavLink to={'/'}>Home</NavLink>
              <span>Contact</span>
            </div>
          </div>
        </div>
      </div>
      <div className='contact'>
        <div className='container'>
          <div className='contact__inner'>
            <div className='contact-title'>Get In Touch With Us</div>
            <div className='contact-text'>
              For More Information About Our Product & Services. Please Feel
              Free To Drop Us An Email. Our Staff Always Be There To Help You
              Out. Do Not Hesitate!
            </div>
            <div className='contact-row'>
              <div className='contact-info'>
                <div className='contact-info-item'>
                  <div className='contact-info-item-icon'>
                    <Point />
                  </div>
                  <div className='contact-info-item-box'>
                    <div className='contact-info-item-box-title'>Address</div>
                    <div className='contact-info-item-box-text'>
                      236 5th SE Avenue, New York NY10000, United States
                    </div>
                  </div>
                </div>
                <div className='contact-info-item'>
                  <div className='contact-info-item-icon'>
                    <Phone />
                  </div>
                  <div className='contact-info-item-box'>
                    <div className='contact-info-item-box-title'>Phone</div>
                    <div className='contact-info-item-box-text'>
                      Mobile: <a href='tel:845466789'>+(84) 546-6789</a>
                    </div>
                    <div className='contact-info-item-box-text'>
                      Hotline: <a href='tel:845466789'>+(84) 456-6789</a>
                    </div>
                  </div>
                </div>
              </div>
              <form className='contact-form'>
                <div className='contact-form-item'>
                  <label>Your name</label>
                  <input className='input' type='text' placeholder='Abc' />
                </div>
                <div className='contact-form-item'>
                  <label>Email address</label>
                  <input
                    className='input'
                    type='email'
                    placeholder='Abc@def.com'
                  />
                </div>
                <div className='contact-form-item'>
                  <label>Subject</label>
                  <input className='input' type='text' placeholder='This is an optional' />
                </div>
                <div className='contact-form-item'>
                  <label>Message</label>
                  <textarea className='textarea' placeholder='Hi! i’d like to ask about'></textarea>
                </div>
                <button>Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ContactPage }
