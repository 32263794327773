import React, { useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'

/**
 * Here I have used the following approach for svg, so that it is possible to edit them
 */
import { ReactComponent as Logo } from '../assets/img/svg/logo.svg'

import { ReactComponent as Cart } from '../assets/img/svg/cart.svg'

function Header({ setIsOpen }) {
  const [isActiveMenuBtn, setActiveMenuBtn] = useState(false)

  /**
   * Switch for hamburger menu
   */
  const handleToggleMenuBtn = () => {
    setActiveMenuBtn(!isActiveMenuBtn)
    document.body.classList.toggle('hiden')
  }

  return (
    <>
      <header className='header'>
        <div className='container'>
          <div className={`header__inner ${isActiveMenuBtn ? 'active' : ''}`}>
            <div className='header__inner-logo'>
              <NavLink to='/'>
                <Logo />
              </NavLink>
            </div>
            <nav
              className={`header__inner-nav ${isActiveMenuBtn ? 'active' : ''}`}
            >
              <ul className='nav-list'>
                <li className='nav-list-item'>
                  <NavLink to='/'>Home</NavLink>
                </li>
                <li className='nav-list-item'>
                  <NavLink to='/shop'>Shop</NavLink>
                </li>
                <li className='nav-list-item'>
                  <NavLink to='/about'>About</NavLink>
                </li>
                <li className='nav-list-item'>
                  <NavLink to='/contact'>Contact</NavLink>
                </li>
              </ul>
            </nav>
            <div className='header__inner-link'>
              <button onClick={() => setIsOpen()}>
                <Cart />
              </button>
            </div>
            <div
              className={`header__menu-btn ${isActiveMenuBtn ? 'open' : ''}`}
              onClick={handleToggleMenuBtn}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export { Header }
