import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Delete } from '../assets/img/svg/delete.svg'

function CartPage() {
  return (
    <>
      <div className='banner-breadcrumbs'>
        <img src={require(`../assets/img/bg-breadcrumbs.png`)} alt='' />
        <div className='container'>
          <div className='banner-breadcrumbs__inner'>
            <div className='banner-breadcrumbs-title'>Cart</div>
            <div className='banner-breadcrumbs-list'>
              <NavLink to={'/'}>Home</NavLink>
              <span>Cart</span>
            </div>
          </div>
        </div>
      </div>
      <div className='cart'>
        <div className='container'>
          <div className='cart__inner'>
            <div className='cart-table-box'>
              <table className='cart-table'>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th className='cart-table-quantity'>Quantity</th>
                    <th>Subtotal</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className='cart-table-product'>
                        <div className='cart-table-product-img'>
                          <img
                            src={require(`../assets/img/Upholstered-Sofa.jpg`)}
                            alt=''
                          />
                        </div>
                        Upholstered Sofa
                      </div>
                    </td>
                    <td>$4,899.99</td>
                    <td className='cart-table-quantity'>
                      <input
                        className='cart-table-number'
                        type='number'
                        defaultValue={'1'}
                      />
                    </td>
                    <td>
                      <div className='cart-table-price'>$4,899.99</div>
                    </td>
                    <td>
                      <button>
                        <Delete />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='cart-panel'>
              <div className='cart-panel-title'>Cart Totals</div>
              <div className='cart-panel-subtotal'>
                <span>Subtotal</span>
                <span>$4,899.99</span>
              </div>
              <div className='cart-panel-total'>
                <span>Total</span>
                <span>$4,899.99</span>
              </div>
              <NavLink to='/checkout' className='btn'>Check Out</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { CartPage }
